<app-header></app-header>

<!-- ***** Wellcome Area End ***** -->

<section class="block-explorer-section section bg-bottom">
    <div class="container">              

        <div class="row">
            <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">Transactions</h2>
                </div>
            </div>
            <div class="offset-lg-3 col-lg-6">
                <div class="center-text">
                    <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="table-responsive">
                    <table class="table table-striped table-latests" style="width:100%">
                        <thead>
                            <tr>
                                <th class="text-center">Restaurant Name</th>
                                <th class="text-center">Address</th>
                                <th class="text-center">State Address</th>                                
                            </tr>
                        </thead>
                        <tbody *ngIf="restaurant_list.status==true">
                            <tr *ngFor="let list of collection.data | paginate: config">
                                <td><a routerLink="/restaurant-kyc/{{list.blockchain_address}}" style="cursor:pointer">{{list.restaurant_name}}</a></td>
                                <td><a routerLink="/restaurant-kyc/{{list.blockchain_address}}" style="cursor:pointer">{{list.address}}</a></td>
                                <td><a routerLink="/restaurant-kyc/{{list.blockchain_address}}" style="cursor:pointer">{{list.blockchain_address}}</a></td>
                            </tr>                            
                        </tbody>
                        <tbody *ngIf="restaurant_list.status==false">
                            <tr>
                                <td colspan="3">No Record Found</td>                                                         
                            </tr>  
                        </tbody>                                                                          
                    </table>
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>                
                <div class="row">
                    <div class="col-lg-12">
                        <div class="center-heading">
                            <h2 class="section-title restaurants">Restaurants</h2>
                        </div>
                    </div>
                    <div class="offset-lg-3 col-lg-6">
                        <div class="center-text">
                            <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                        </div>
                    </div>
                </div>                                

                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >  
                    <div ngxSlickItem *ngFor="let image of restaurant_list.data" class="slide">  
                      <img src="{{imageBase}}{{ image.image }}" width="100%" height= "200px">  
                    </div>  
                  </ngx-slick-carousel> 

            </div>
        </div>

    </div>
</section>


<app-footer></app-footer>