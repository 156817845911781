<header class="header-area ">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <nav class="main-nav">
                    <!-- ***** Logo Start ***** -->
                    <a routerLink="/" class="logo" >
                        <img src="assets/images/logos/white.png"  class="light-logo logoImg" width="188px" height="44px" alt="Eatzilla Explorer" />
                        <img src="assets/images/logos/color.png" class="dark-logo logoImg" width="188px" height="44px" alt="Eatzilla Explorer" />
                    </a>
                    <!-- ***** Logo End ***** -->

                    <!-- ***** Lang Start ***** -->

                    <!-- ***** Lang End ***** -->

                    <!-- ***** Menu Start ***** -->
                    <ul class="nav" [ngClass]="isMenuExpanded?'d-block':'d-none'">
                        <li><a routerLink="/">HOME</a></li>
                        <li><a routerLink="/latest-blocks">LATEST BLOCKS</a></li>
                        <li><a routerLink="/restaurant">RESTAURANT KYC</a></li>
                        <li><a routerLink="/driver">DRIVER KYC</a></li>
                        <li><a routerLink="/orders">ORDER DETAILS</a></li>
                        <li *ngIf="currentUser && currentUser==1"><a routerLink="/commission">COMMISSION</a></li>
                        <li *ngIf="currentUser && currentUser!=1"><a routerLink="/provider_commission">COMMISSION</a></li>
                        <li *ngIf="currentUser"><a routerLink="/payout">PAYOUTS</a></li>
                        <li *ngIf="!currentUser"><a href="#" data-target="#login" data-toggle="modal" class="btn-nav-box login-trigger">LOGIN </a></li>
                        <li *ngIf="currentUser"><a href="#" class="btn-nav-box login-trigger" (click)="logout()">LOGOUT </a></li>
                    </ul>
                    <a class='menu-trigger' (click)="toggleMenu()">
                        <span>Menu</span>
                    </a>
                    <!-- ***** Menu End ***** -->
                </nav>
            </div>
        </div>
    </div>
</header>

<section class="block-explorer-wrapper bg-bottom-center" id="welcome-1">
    <div class="block-explorer text">
        <div class="container text-center">
            <div class="row">
                <div class="col-lg-12 align-self-center">
                    <h1>Blockchain Explorer</h1>
                </div>
                <div class="offset-lg-3 col-lg-6">
                    <p>Up To Block 1883224</p>
                </div>
            </div>
        </div>
    </div>
    <div class="search">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="input-wrapper">
                        <div class="input">

                            <input type="text" [(ngModel)]="res_address" value="{{res_address}}" placeholder="block address...">
                            <a routerLink="/transaction-details/{{res_address}}"><button><i class="fa fa-search"></i></button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="login" class="modal fade" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-body">
                <button data-dismiss="modal" class="close">&times;</button>
                <h4 class="login-title">Login</h4>
                <form [formGroup]="loginForm">
                    <input type="text" formControlName="email" name="email" class="username form-control" placeholder="Username" />
                    <div *ngIf="submitted &amp;&amp; loginForm.controls.email.errors" class="text-danger">
                        <div *ngIf="loginForm.controls.email.errors.required">Username is required</div>
                        <!-- <div *ngIf="loginForm.controls.email.errors.email">Email must be a valid email address</div> -->
                    </div>
                    <input type="password" formControlName="password" name="Password" class="password form-control" placeholder="Password" />
                    <div *ngIf="submitted &amp;&amp; loginForm.controls.password.errors" class="text-danger">
                        <div *ngIf="loginForm.controls.password.errors.required">Password is required</div>
                        <div *ngIf="loginForm.controls.password.errors.incorrect">Invalid Login Details</div>
                    </div>
                    <input class="btn login float-right" (click)="login()" type="submit" value="Login" />
                </form>
            </div>
        </div>
    </div>
</div>
