<app-header></app-header>


<!-- <app-transaction-details></app-transaction-details> -->
<section class="block-explorer-features section bg-bottom" style="padding-bottom: 0px!important;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">General Information</h2>
                </div>
            </div>
            <div class="offset-lg-3 col-lg-6">
                <div class="center-text">
                    <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div class="item">
                    <div class="title">
                        <div class="icon"></div>
                        <h5>Total Restaurants</h5>
                    </div>
                    <div class="text">
                        <span *ngIf="dashboard_data.data">{{dashboard_data.data[0].restaurant_count}}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div class="item">
                    <div class="title">
                        <div class="icon"></div>
                        <h5>Total Drivers</h5>
                    </div>
                    <div class="text">
                        <span *ngIf="dashboard_data.data">{{dashboard_data.data[0].driver_count}}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div class="item">
                    <div class="title">
                        <div class="icon"></div>
                        <h5>Total Users</h5>
                    </div>
                    <div class="text">
                        <span *ngIf="dashboard_data.data">{{dashboard_data.data[0].user_count}}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div class="item">
                    <div class="title">
                        <div class="icon"></div>
                        <h5>Total Orders</h5>
                    </div>
                    <div class="text">
                        <span *ngIf="dashboard_data.data">{{dashboard_data.data[0].order_count}}</span>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>

<section class="section bg-bottom" id="what-is-ico">
    <div class="container">
        <!-- ***** About Start ***** -->
        <div class="row m-bottom-70">
            <div class="col-lg-6 col-md-6 col-sm-12 align-self-center">
                <div class="left-heading">
                    <h2 class="section-title">A compelling value proposition.</h2>
                </div>
                <div class="left-text">
                    <p class="dark">ARDA (ARD) is an open source, Bitcoin-like digital currency which uses a proof of work script algorithm.</p>
                    <p>The genesis block was mined on March 1st, 2014. The total number of mineable ARD is 245,465,283. The mining of Arda is divided into Epochs: each Epoch mines 36000 blocks of coins and is targeted to last approximately 25 days. </p>
                </div>
                <a href="#" class="btn-secondary-line mobile-bottom-fix">Download Whitepaper</a>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 align-self-center">
                <img src="assets/images/theme-1-about.svg" class="img-fluid float-right" alt="Arda ICO">
            </div>
        </div>
        <!-- ***** About End ***** -->
        <!-- ***** Features Items Start ***** -->
        <div class="row" style="padding-top: 3em;">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div class="features-elliptical elliptical-bottom" data-scroll-reveal="enter right move 30px over 0.6s after 0.2s">
                    <div class="icon">
                        <i class="fa fa-cube"></i>
                    </div>
                    <h5 class="features-title">Easy Token Integration</h5>
                    <p>Morbi pharetra sapien ut mattis viverra. Curabitur sit amet mattis magna lipsum.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div class="features-elliptical elliptical-top" data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                    <div class="icon">
                        <i class="fa fa-lock"></i>
                    </div>
                    <h5 class="features-title">Advanced Security</h5>
                    <p>Donec pellentesque turpis utium lorem imperdiet semper. Ut mat viverra mattis.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" data-scroll-reveal="enter right move 30px over 0.6s after 0.6s">
                <div class="features-elliptical">
                    <div class="icon">
                        <i class="fa fa-server"></i>
                    </div>
                    <h5 class="features-title">Decentralized</h5>
                    <p>Proin arcu ligula, malesuada id tincidunt laoreet, facilisis at justo. Sed at lorem malesuada.</p>
                </div>
            </div>
        </div>
        <!-- ***** Features Items End ***** -->
    </div>
</section>
<app-footer></app-footer>