import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-latest-block',
  templateUrl: './latest-block.component.html',
  styleUrls: ['./latest-block.component.css']
})
export class LatestBlockComponent implements OnInit {
  str: string;
  baseUrl :any = environment.baseUrl;
  block_data : any = []
  text1: string;
    text2: string;
    textModal: string;
    isCopied1: boolean;

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.get_latest_blocks();
  }
  //  myFunction() {
  //   var str = "6cf8181c61fabf703bbceb45802821212d1be2fed3462c6cd73f5a526ff47e61e607cd";
  //   var res = str.slice(0, 10);
  //   document.getElementById("demo").innerHTML = res;
  // }

  showFull(arg){
    $(document).ready(function(){
      // alert("popover")
      $('body').popover({
        selector: '[data-toggle="popover"]',
        trigger: 'click',
        container:'body',
        content : arg ,
        animation:false
     }).on('hide.bs.popover', function () {
         if ($(".popover:hover").length) {
           return false;
         }
     });

     $('body').on('mouseleave', '.popover', function(){
          $('.popover').popover('hide');
     });
    });
  }



  get_latest_blocks = () => {
    this.http.get(this.baseUrl+'/api/latest-block').subscribe( (data:any) => {
      console.log(data);
      this.block_data = data;
      return this.block_data;
    })

  }

}
