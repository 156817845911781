<app-header></app-header>

<section class="block-explorer-section section bg-bottom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">Commission Details</h2>
                </div>
            </div>
            <div class="offset-lg-3 col-lg-6">
                <div class="center-text">
                    <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                </div>
            </div>
        </div>
        <div class="row m-bottom-70">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="table-responsive">
                    <table class="table table-striped table-latests table-detail">                        
                        <tbody>
                            <tr *ngIf="commission_data.city">
                                <td><strong>City Name</strong></td>
                                <td>{{commission_data.city}}</td>
                            </tr>
                            <tr>
                                <td><strong>State Address</strong></td>
                                <td><a href="#/transaction-details/{{commission_data.commission_blockchain_transaction_id}}" style="cursor:pointer">{{commission_data.commission_blockchain_transaction_id}}</a></td>
                            </tr>
                            <tr>
                                <td><strong>Admin Commission</strong></td>
                                <td>{{commission_data.admin_commision}} %</td>
                            </tr>
                            <tr>
                                <td><strong>Driver Base Price</strong></td>
                                <td>{{commission_data.driver_base_price}} </td>
                            </tr>
                            <tr>
                                <td><strong>Minimum distance</strong></td>
                                <td>{{commission_data.min_dist_base_price}} KM</td>
                            </tr>
                            <!-- <tr>
                                <td><strong>From  </strong></td>
                                <td>0x77fe76917bfb60ebc12be463c7aec9bd1f5a9a1a
                                </td>
                            </tr>
                            <tr>
                                <td><strong>To</strong></td>
                                <td>0xab42364dd159cb5a510f580c1a0a42a1ebf66b38 </td>
                            </tr> -->
                            <!-- <tr>
                                <td><strong>Value</strong></td>
                                <td>0.0000171 Ether ($0.00)</td>
                            </tr>
                            <tr>
                                <td><strong>Transaction Fee</strong></td>
                                <td>0.000021 Ether ($0.01)
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Gas Limit</strong></td>
                                <td>
                                    50,000</td>
                            </tr>
                            <tr>
                                <td><strong>Gas Used by Transaction</strong></td>
                                <td>21,000 (42%) </td>
                            </tr>
                            <tr>
                                <td><strong>Gas Price</strong></td>
                                <td>0.000000001 Ether (1 Gwei)
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Nonce</strong></td>
                                <td>
                                    14999
                                </td>
                            </tr> -->
                            
                        </tbody>
                        <tfoot>
                            <!-- <tr>
                                <td>Private note</td>
                                <td>To access the Private Note feature, you must be Logged In</td>
                            </tr> -->
                        </tfoot>
                    </table>
                </div>
            </div>

        </div>

    </div>
</section>

<app-footer></app-footer>