<app-header></app-header>

<!-- ***** Wellcome Area End ***** -->

<section class="block-explorer-section section bg-bottom">
    <div class="container">              

        <div class="row">
            <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">Transactions</h2>
                </div>
            </div>
            <div class="offset-lg-3 col-lg-6">
                <div class="center-text">
                    <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="table-responsive">
                    <table class="table table-striped table-latests" style="width:100%">
                        <thead>
                            <tr>
                                <th class="text-center">Order ID</th>
                                <th class="text-center">Delivery Address</th>
                                <th class="text-center">State Address</th>   
                                <th class="text-center">Delivery Type</th>                             
                                <th class="text-center">Bill Amount</th>
                                <th class="text-center">Order Time</th>
                                <th class="text-center">Status</th>                                
                            </tr>
                        </thead>
                        <tbody *ngIf="order_list.status==true">
                            <tr *ngFor="let list of collection.data | paginate: config">
                                <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.order_id}}</a></td>
                                <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.delivery_address}}</a></td>
                                <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.blockchain_transaction_id}}</a></td>
                                <td *ngIf="list.delivery_type==1"><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">Home Delivery</a></td>
                                <td *ngIf="list.delivery_type==2"><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">Pickup</a></td>
                                <td *ngIf="list.delivery_type==3"><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">Dining</a></td>
                                <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.bill_amount}}</a></td>
                                <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.ordered_time}}</a></td>
                                <td *ngIf="list.status==0"><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">New</a></td>
                                <td *ngIf="list.status==1||list.status==3||list.status==4||list.status==5||list.status==6"><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">Processing</a></td>
                                <td *ngIf="list.status==7"><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">Completed</a></td>
                                <td *ngIf="list.status==10"><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">Cancelled</a></td>
                            </tr>                            
                        </tbody>
                        <tbody *ngIf="order_list.status==false">
                            <tr>
                                <td colspan="3">No Record Found</td>                                                         
                            </tr>  
                        </tbody>                                                                          
                    </table>
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>                                                            
               
            </div>
        </div>

    </div>
</section>


<app-footer></app-footer>