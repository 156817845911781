<footer id="contact">
    <div class="footer-bottom slim">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p class="copyright">2022 © Eatzilla</p>
                </div>
            </div>
        </div>
    </div>
</footer>