import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-provider-commission',
  templateUrl: './provider-commission.component.html',
  styleUrls: ['./provider-commission.component.css']
})
export class ProviderCommissionComponent implements OnInit {

  baseUrl :any = environment.baseUrl;
  commission_data;
  role;
  _id;
  currentUser;
  
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.currentUser = sessionStorage.getItem('role');
    this.get_commission_details();

  }

  get_commission_details = () => {
    this.role = sessionStorage.getItem('role');
    this._id = sessionStorage.getItem('_id');
    this.http.get(this.baseUrl+'/api/provider-commission-data/'+this.role+'/'+this._id).toPromise().then( (data:any) => {      
      this.commission_data = data.data;      
      console.log(this.commission_data) 
      return this.commission_data;
    });
  }

}
