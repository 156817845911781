import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { logging } from 'protractor';
import { constants } from 'buffer';
import { NgxSpinnerService } from "ngx-spinner";


declare var $:any;

@Component({
  selector: 'app-restaurant-kyc',
  templateUrl: './restaurant-kyc.component.html',
  styleUrls: ['./restaurant-kyc.component.css']
})
export class RestaurantKycComponent implements OnInit {

  baseUrl :any = environment.baseUrl;
  imageBase : any = environment.imageBase;
  restaurant_kyc : any = [];
  config: any;
  collection = { count: 0, data: [] };
  res_address;
  

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }


  get_restaurantkyc_old(data = null){
    this.spinner.show();
    if(data==null) data = this.res_address;
    this.http.get(this.baseUrl+'/api/restaurant-kyc/'+data).subscribe( (data:any) => {
      this.restaurant_kyc = data;      
      console.log(this.restaurant_kyc)
      if(this.restaurant_kyc.data){
        this.collection.count = this.restaurant_kyc.data.length;
        this.collection.data = this.restaurant_kyc.data;
      }
      this.config = {
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: this.collection.count
      };
      this.router.navigateByUrl('/restaurant-kyc', { skipLocationChange: true }).then(() => {
        this.spinner.hide();  
        this.router.navigate(['restaurant-kyc']);          
      }); 
    });
  }

  ngOnInit(): void {
    
    let queryParam = this.route.snapshot.params.address;
    console.dir(queryParam);  
    this.get_restaurantkyc(queryParam);    

  }

  

  get_restaurantkyc = (queryParam) => { 
    this.spinner.show();
    this.http.get(this.baseUrl+'/api/restaurant-kyc/'+queryParam).subscribe( (data:any) => {
      this.restaurant_kyc = data;      
      console.log(this.restaurant_kyc)
      if(this.restaurant_kyc.data){
        this.collection.count = this.restaurant_kyc.data.length;
        this.collection.data = this.restaurant_kyc.data;
      }
      this.config = {
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: this.collection.count
      };
      this.spinner.hide(); 
      return this.collection;
    });
   
    



  }

}
