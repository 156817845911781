import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  baseUrl :any = environment.baseUrl;
  order_list : any = [];
  config: any;
  collection = { count: 0, data: [] };
  
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.get_orders(); 

  }

  pageChanged(event){
    this.config.currentPage = event;
  }

  get_orders = () => {
      this.http.get(this.baseUrl+'/api/order-list').subscribe( (data:any) => {
        console.log(data)
        this.order_list = data;
        this.collection.count = this.order_list.data.length;
        this.collection.data = this.order_list.data;
        this.config = {
          itemsPerPage: 10,
          currentPage: 1,
          totalItems: this.collection.count
        };
        return this.collection;
      });
  }

}
