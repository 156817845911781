import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PopoverModule } from "ngx-smart-popover";
import { ClipboardModule } from 'ngx-clipboard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LatestBlockComponent } from './latest-block/latest-block.component';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { RestaurantKycComponent } from './restaurant/restaurant-kyc/restaurant-kyc.component';
import { RestaurantComponent } from './restaurant/restaurant/restaurant.component';
import { DriverComponent } from './driver/driver/driver.component';
import { OrderComponent } from './order/order.component';
import { NotFoundComponent } from './not-found/not-found.component';

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxPaginationModule } from 'ngx-pagination';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSpinnerModule } from "ngx-spinner";
import { ReactiveFormsModule } from '@angular/forms';
import { CommissionComponent } from './commission/commission.component';
import { PayoutComponent } from './payout/payout.component';
import { ProviderCommissionComponent } from './commission/provider-commission/provider-commission.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    FooterComponent,
    LatestBlockComponent,
    RestaurantKycComponent,
    TransactionDetailsComponent,
    RestaurantComponent,
    DriverComponent,
    OrderComponent,
    NotFoundComponent,
    CommissionComponent,
    PayoutComponent,
    ProviderCommissionComponent,
    UnauthorizedComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    SlickCarouselModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    PopoverModule,
    ClipboardModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
