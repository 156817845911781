<app-header></app-header>

<section class="block-explorer-section section bg-bottom">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">Un</h2>
                </div>
            </div> -->

        </div>
        <div class="row m-bottom-70">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                    <!-- <div class="card-title">
                        Overview
                    </div> -->
                    <div class="offset-lg-3 col-lg-6 mt-4">
                        <div class="center-heading">
                            <h2 class="section-title">Page Not Found</h2>
                        </div>
                        <div class="center-text">
                            <p>This page is not publicly available.<br> To access it please login first</p>
                        </div>
                    </div>

                    <div class="card-body text-center mt--4 ">
                        <img src="assets/images/404-error.png" width="25%" alt="Eatzilla Explorer" />
                        <p>Sorry, We aren't able to locate you transacation hash</p>
                    </div>

                    <div class="card-footer">
                        <div class="alert alert-dark">
                            <p class="alert-text mb-0 f-12"><i class="fa fa-info-circle mr-1"></i> Eatzilla only provides an overview of the current state of the blockchain such as your transaction status, <strong> but we have no control over these transactions</strong>.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</section>

<app-footer></app-footer>
