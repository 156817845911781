import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  baseUrl :any = environment.baseUrl;  
  dashboard_data: any = [];

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.get_dashboard_details();

  }

  get_dashboard_details = () => {
      this.http.get(this.baseUrl+'/api/dashboard-data').subscribe( (data:any) => {
        this.dashboard_data = data;        
        return this.dashboard_data;
      });
  }

}
