import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

import { AuthCheckService } from "./../service/auth-check.service";

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.css']
})
export class PayoutComponent implements OnInit {
  currentUser;
  _id;
  baseUrl :any = environment.baseUrl;
  payout_data : any = [];
  payout_details : any = [];
  config: any;
  collection = { count: 0, data: [] };
  driver_collection = { count: 0, data: [] };

  constructor(private authservice: AuthCheckService, private http: HttpClient, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    if(this.authservice.checkAuth())
    {
      this.get_payout_details();
    }         
  }

  pageChanged(event){
    this.config.currentPage = event;
  }

  get_payout_details = () => {
    this._id = sessionStorage.getItem('_id');
    this.http.get(this.baseUrl+'/api/payout-data/'+this.currentUser+'/'+this._id).subscribe( (data:any) => {
      console.log(data) 
      this.payout_details = data;
      this.payout_data = this.payout_details.data.payout_details;
      this.collection = this.payout_details.data.restaurant_payout_history;
      this.driver_collection = this.payout_details.data.driver_payout_history;      
      console.log(this.collection) 
      return this.collection;
    });
  }

}
