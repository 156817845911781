import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.css']
})
export class TransactionDetailsComponent implements OnInit {

  baseUrl :any = environment.baseUrl;
  search_data : any = {};
  currentUser;
  _id

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit(): void { 
    this.currentUser = sessionStorage.getItem('role');
    this._id = sessionStorage.getItem('_id');
    this.search_data = JSON.parse(localStorage.getItem('search_data'));
    console.log("search_data",localStorage.getItem(this.search_data))
    let queryParam = this.route.snapshot.params.address;
    this.get_address_details(queryParam);

  }

  get_address_details = (res_address) => {
    this.spinner.show();
    this.http.get(this.baseUrl+'/api/get-block-details/'+res_address+'/'+this.currentUser+'/'+this._id).subscribe( (data:any) => {
        localStorage.setItem("search_data",JSON.stringify(data));
        this.search_data = data;
        console.log("search_data",this.search_data)
        if(data.status==false){
          this.router.navigateByUrl('/not-found', { skipLocationChange: true }).then(() => {
            this.spinner.hide();
            this.router.navigate(['not-found']);
          });
        }else{
          if(data.data.error){
            this.router.navigateByUrl('/not-found', { skipLocationChange: true }).then(() => {
              this.spinner.hide();
              this.router.navigate(['not-found']);
            });
          }else{
            
            this.router.navigateByUrl('/transaction-details/'+res_address, { skipLocationChange: true }).then(() => {
              this.spinner.hide();
              this.router.navigate(['transaction-details/'+res_address]);
            });
          }
        }
      });
  }

}
