<app-header></app-header>

<!-- ***** Wellcome Area End ***** -->

<section class="block-explorer-section section bg-bottom">
    <div class="container">              

        <div class="row">
            <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">Transactions</h2>
                </div>
            </div>
            <div class="offset-lg-3 col-lg-6">
                <div class="center-text">
                    <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="table-responsive">
                    <table class="table table-striped table-latests" style="width:100%">
                        <thead>
                            <tr>
                                <th class="text-center">Driver Name</th>
                                <th class="text-center">Mobile</th>
                                <!-- <th class="text-center">License</th> -->
                                <th class="text-center">State Address</th>      
                                <th class="text-center">Blockchain Verification Status</th>                           
                            </tr>
                        </thead>
                        <tbody *ngIf="driver_list.status==true">
                            <tr *ngFor="let list of collection.data | paginate: config">
                                <td><a routerLink="/transaction-details/{{list.kyc_blockchain_transaction_id}}" style="cursor:pointer">{{list.name}}</a></td>
                                <td><a routerLink="/transaction-details/{{list.kyc_blockchain_transaction_id}}" style="cursor:pointer">{{list.phone}}</a></td>
                                <!-- <td><a routerLink="/transaction-details/{{list.kyc_blockchain_transaction_id}}" style="cursor:pointer"><img src="{{imageBase}}{{list.license}}" width="200px" height="160px"></a></td> -->
                                <td><a routerLink="/transaction-details/{{list.kyc_blockchain_transaction_id}}" style="cursor:pointer">{{list.kyc_blockchain_transaction_id}}</a></td>
                                <td *ngIf="list.block_status==1"><a routerLink="/transaction-details/{{list.kyc_blockchain_transaction_id}}" style="cursor:pointer"><h5><span class="green">Verified</span></h5></a></td>
                                <td *ngIf="list.block_status==2"><a routerLink="/transaction-details/{{list.kyc_blockchain_transaction_id}}" style="cursor:pointer"><h5><span class="red">Not Verified</span></h5></a></td>
                            </tr>                            
                        </tbody>
                        <tbody *ngIf="driver_list.status==false">
                            <tr>
                                <td colspan="5">No Record Found</td>                                                         
                            </tr>  
                        </tbody>                                                                          
                    </table>
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>                
               
            </div>
        </div>

    </div>
</section>


<app-footer></app-footer>