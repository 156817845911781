import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LatestBlockComponent } from './latest-block/latest-block.component';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { RestaurantComponent } from './restaurant/restaurant/restaurant.component';
import { RestaurantKycComponent } from './restaurant/restaurant-kyc/restaurant-kyc.component';
import { DriverComponent } from './driver/driver/driver.component'
import { OrderComponent } from './order/order.component'
import { NotFoundComponent } from './not-found/not-found.component';
import { CommissionComponent } from './commission/commission.component';
import { PayoutComponent } from './payout/payout.component';
import { ProviderCommissionComponent } from './commission/provider-commission/provider-commission.component'
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';


const routes: Routes = [
  { path:'', component:DashboardComponent},
  { path:'latest-blocks', component:LatestBlockComponent},
  { path:'transaction-details/:address', component:TransactionDetailsComponent},
  { path:'restaurant', component:RestaurantComponent},
  { path:'restaurant-kyc/:address', component:RestaurantKycComponent},
  { path:'driver', component:DriverComponent},
  { path:'orders', component:OrderComponent},
  { path:'not-found', component:NotFoundComponent},
  { path:'commission', component:CommissionComponent},
  { path:'payout', component:PayoutComponent},
  { path:'unauthorized', component:UnauthorizedComponent},
  { path: 'provider_commission', component:ProviderCommissionComponent}
];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
