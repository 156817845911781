<app-header></app-header>

<!-- ***** Wellcome Area End ***** -->

<section class="block-explorer-section section bg-bottom">
    <div class="container">

        <div class="row">
            <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">Commission Details</h2>
                </div>
            </div>
            <div class="offset-lg-3 col-lg-6">
                <div class="center-text">
                    <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="" id="commissionWrapper">
                    <!-- Nav tabs -->
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active show" data-toggle="tab" href="#tab1">Restaurant Based</a>
                        </li>
                        <li class="nav-item" *ngIf="currentUser && currentUser==1">
                            <a class="nav-link" data-toggle="tab" href="#tab2">City Based</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu2">Menu 2</a>
                        </li> -->
                    </ul>

                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div id="tab1" class="container tab-pane active" ><br>
                            <div class="table-responsive">
                                <table class="table table-striped table-latests" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Restaurant Name</th>                                            
                                            <th class="text-center">Block Address</th>
                                            <th class="text-center">Admin Commission</th>
                                            <th class="text-center">Driver Base Price</th>
                                            <th class="text-center">Minimum distance</th>
                                            <th class="text-center">Extra Fee Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="commission_data.status">
                                        <tr *ngFor="let list of collection.data | paginate: config">
                                            <td><a href="transaction-details/{{list.commission_blockchain_transaction_id}}" style="cursor:pointer">{{list.restaurant_name}}</a></td>
                                            <td><a href="transaction-details/{{list.commission_blockchain_transaction_id}}" style="cursor:pointer">{{list.commission_blockchain_transaction_id}}</a></td>
                                            <td><a href="transaction-details/{{list.commission_blockchain_transaction_id}}" style="cursor:pointer">{{list.admin_commision}} %</a></td>
                                            <td><a href="transaction-details/{{list.commission_blockchain_transaction_id}}" style="cursor:pointer">{{list.driver_base_price}}</a></td>
                                            <td><a href="transaction-details/{{list.commission_blockchain_transaction_id}}" style="cursor:pointer">{{list.min_dist_base_price}} KM</a></td>
                                            <td><a href="transaction-details/{{list.commission_blockchain_transaction_id}}" style="cursor:pointer">{{list.extra_fee_amount}}</a></td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="commission_data.status==false">
                                        <tr>
                                            <td colspan="6">No Record Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pagination-controls id="restaurant_pagination" (pageChange)="pageChanged($event)"></pagination-controls>
                                
                            </div>
                        </div>
                        <div id="tab2" class="container tab-pane fade" *ngIf="currentUser && currentUser==1"><br>
                            <div class="table-responsive">
                                <table class="table table-striped table-latests" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th class="text-center">City Name</th>                                            
                                            <th class="text-center">State Address</th>
                                            <th class="text-center">Admin Commission</th>
                                            <th class="text-center">Driver Base Price</th>
                                            <th class="text-center">Minimum distance</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="commission_data.status">
                                        <tr *ngFor="let citylist of city_collection.data | paginate: cityconfig">
                                            <td><a href="#/transaction-details/{{citylist.commission_blockchain_transaction_id}}" style="cursor:pointer">{{citylist.city}}</a></td>
                                            <td><a href="#/transaction-details/{{citylist.commission_blockchain_transaction_id}}" style="cursor:pointer">{{citylist.commission_blockchain_transaction_id}}</a></td>
                                            <td><a href="#/transaction-details/{{citylist.commission_blockchain_transaction_id}}" style="cursor:pointer">{{citylist.admin_commision}} %</a></td>
                                            <td><a href="#/transaction-details/{{citylist.commission_blockchain_transaction_id}}" style="cursor:pointer">{{citylist.driver_base_price}}</a></td>
                                            <td><a href="#/transaction-details/{{citylist.commission_blockchain_transaction_id}}" style="cursor:pointer">{{citylist.min_dist_base_price}} KM</a></td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="commission_data.status==false">
                                        <tr>
                                            <td colspan="5">No Record Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pagination-controls id="city_pagination" (pageChange)="citypageChanged($event)"></pagination-controls>
                            </div>
                        </div>
                        <!-- <div id="menu2" class="container tab-pane fade"><br>
                            <h3>Menu 2</h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                        </div> -->
                    </div>
                </div>



            </div>
        </div>

    </div>
</section>


<app-footer></app-footer>