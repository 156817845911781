import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  baseUrl :any = environment.baseUrl;
  loginUrl : any = "https://api-admin.eatzilla.info/api/explorer_login";
  imageBase : any = environment.imageBase;
  search_data: any = [];
  config: any;
  collection = { count: 0, data: [] };
  res_address;
  currentUser
  userrole
  loginForm: FormGroup;
  submitted = false;
  isMenuExpanded = false;
  public isScrolledBeforeLogo : boolean = true;  

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  get_address_details = () => {
    this.spinner.show();
    this.http.get(this.baseUrl+'/api/get-block-details/'+this.res_address).subscribe( (data:any) => {
        localStorage.setItem("search_data",JSON.stringify(data));
        if(data.status==false){
          this.router.navigateByUrl('/#/not-found', { skipLocationChange: true }).then(() => {
            this.spinner.hide();
            this.router.navigate(['not-found']);
          });
        }else{
          if(data.data.error){
            this.router.navigateByUrl('/#/not-found', { skipLocationChange: true }).then(() => {
              this.spinner.hide();
              this.router.navigate(['not-found']);
            });
          }else{

            this.router.navigateByUrl('/#/transaction-details/'+this.res_address, { skipLocationChange: true }).then(() => {
              this.spinner.hide();
              this.router.navigate(['transaction-details/'+this.res_address]);
            });
          }
        }
      });
  }


  login = () => {

    this.submitted = true;
    if (this.loginForm.invalid) {
        return;
    }
    this.http.post(this.loginUrl,this.loginForm.value).subscribe( (data:any) => {

      if(data.status==true){
        sessionStorage.setItem('role', data.role)
        sessionStorage.setItem('name', data.user_name)
        sessionStorage.setItem('_id', data.user_id)
        window.location.reload();
      }else{
        this.loginForm.controls['password'].setErrors({'incorrect': true});
      }
    })
  }

  logout = () => {
    sessionStorage.removeItem('role')
    sessionStorage.removeItem('name')
    this.init();
  }

  reloadComponent = () => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([this.router.url]);
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
        email: ['', [Validators.required]],
        password: ['', [Validators.required]]
    });

    this.currentUser = sessionStorage.getItem('role');


    // var initialSrc = "assets/images/logos/white.png";
    // var scrollSrc = "assets/images/logos/color.png";
    $(window).scroll(function(){

      // var value = $(this).scrollTop();
      // if (value > 50) {
      //   $(".logoImg").attr("src", scrollSrc);
      //   console.log("src", scrollSrc)
      // }
      // else {
      //   $(".logoImg").attr("src", initialSrc);
      //   console.log("src", initialSrc)
      // }
         

      $('header').toggleClass('scrolled header-sticky', $(this).scrollTop() > 50);
      console.log("scroll")
      this.isScrolledBeforeLogo = false;
    });
  }

  init = () => {
    this.currentUser = sessionStorage.getItem('role');

    $(window).scroll(function(){
      $('header').toggleClass('scrolled header-sticky', $(this).scrollTop() > 50);
    });
  }

  toggleMenu() {
    this.isMenuExpanded = !this.isMenuExpanded;
    console.log("hgh", this.isMenuExpanded)
  }

}
