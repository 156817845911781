<app-header></app-header>

<section class="block-explorer-section section bg-bottom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">Latest Blocks</h2>
                </div>
            </div>
            <div class="offset-lg-3 col-lg-6">
                <div class="center-text">
                    <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="table-responsive">
                    <table class="table table-striped table-latests">
                        <thead>
                            <tr>
                                <td>Block Height</td>
                                <td>Block ID</td>
                                <!-- <td>Timestamp</td>                                 -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let list of block_data.data">
                                <td>{{list.block_num}}</td>



                                <td [popover]="myPopover" [popoverOnHover]="false" (click)="showFull(list.header_id)">{{list.header_id.slice(0, 10)}}...</td>

                                <!-- <td popover="arg" (click)="showFull(list.header_id)" popoverPlacement="top" [popoverOnHover]="false" [popoverCloseOnClickOutside]="true" [popoverCloseOnMouseOutside]="false" [popoverDisabled]="false" [popoverAnimation]="true" [popoverDismissTimeout]="1000">{{list.header_id.slice(0, 10)}}...</td> -->
                                <!-- <td data-toggle="popover" data-placement="bottom" (click)="showFull(list.header_id)">{{list.header_id.slice(0, 10)}}...</td> -->
                                <!-- <td>{{list.nounce}}</td>
                                                        -->
                                <popover-content #myPopover placement="top" [animation]="true" [closeOnClickOutside]="true">{{list.header_id}}...<i class="fa fa-clipboard" ngxClipboard [cbContent]="text1" (cbOnSuccess)="isCopied1 = true" [class.btn-success]="isCopied1"></i>
                                </popover-content>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
