import { Injectable } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthCheckService {

  currentUser;

  constructor(private router: Router) { }

  checkAuth = () => {
    this.currentUser = sessionStorage.getItem('role');
    if(!this.currentUser || this.currentUser==null)
    {
        this.router.navigateByUrl('/unauthorized', { skipLocationChange: true }).then(() => {
        this.router.navigate(['unauthorized']);
      });
    }else{
      return true;
    }
  }
}


