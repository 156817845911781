<app-header></app-header>

<!-- ***** Wellcome Area End ***** -->

<section class="block-explorer-section section bg-bottom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">Restaurant Basic Profile</h2>
                </div>
            </div>
            <div class="offset-lg-3 col-lg-6">
                <div class="center-text">
                    <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                </div>
            </div>
        </div>
        <div class="row m-bottom-70">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="qr" *ngIf="restaurant_kyc.data">
                    <img src="{{imageBase}}{{restaurant_kyc.data[0].image}}" class="img-fluid d-block mx-auto" alt="">
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="table-responsive">
                    <table class="table table-striped table-latests table-detail">
                        <tbody>
                            <tr>
                                <td><strong>Name</strong></td>
                                <td *ngIf="restaurant_kyc.data">{{restaurant_kyc.data[0].restaurant_name}}</td>
                            </tr>
                            <tr>
                                <td><strong>Address</strong></td>
                                <td *ngIf="restaurant_kyc.data">{{restaurant_kyc.data[0].blockchain_address}}</td>
                            </tr>
                            <tr>
                                <td><strong>Rating</strong></td>
                                <td *ngIf="restaurant_kyc.data">{{restaurant_kyc.data[0].rating}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">Transactions</h2>
                </div>
            </div>
            <div class="offset-lg-3 col-lg-6">
                <div class="center-text">
                    <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">                
                <div class="table-responsive">
                    <table class="table table-striped table-latests" style="width:100%" >
                        <thead>
                            <tr>
                                <th class="text-center">Document Name</th>
                                <th class="text-center">Document</th>
                                <th class="text-center">Block Verification Status</th>                                
                            </tr>
                        </thead>
                        <tbody *ngIf="restaurant_kyc.status==true">
                            <tr *ngFor="let list of restaurant_kyc.data">
                                <td>{{list.document_name}}</td>
                                <td><img src="{{list.document}}" width="200px" height="160px"></td>
                                <td *ngIf="list.block_status==1"><h5><span class="green">Verified</span></h5></td>
                                <td *ngIf="list.block_status==2"><h5><span class="red">Not Verified</span></h5></td>
                            </tr>                            
                        </tbody>
                        <tbody *ngIf="restaurant_kyc.status==false">
                            <tr>
                                <td colspan="3">No Record Found</td>                                                         
                            </tr>  
                        </tbody>                                                                          
                    </table>
                </div>                

            </div>
        </div>

    </div>
</section>


<app-footer></app-footer>