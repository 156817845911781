<app-header></app-header>

<!-- ***** Wellcome Area End ***** -->
<section class="block-explorer-features section bg-bottom" style="padding-bottom: 0px!important;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">Payout Details</h2>
                </div>
            </div>
            <div class="offset-lg-3 col-lg-6">
                <div class="center-text">
                    <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngIf="currentUser==1">
                <div class="item">
                    <div class="title">
                        <div class="icon"></div>
                        <h5>Restaurants Pending Payout</h5>
                    </div>
                    <div class="text">
                        <span *ngIf="payout_data">{{payout_data[0].restaurant_pay}}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngIf="currentUser==1">
                <div class="item">
                    <div class="title">
                        <div class="icon"></div>
                        <h5>Drivers Pending Payout</h5>
                    </div>
                    <div class="text">
                        <span *ngIf="payout_data">{{payout_data[0].driver_pay}}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngIf="currentUser!=1">
                <div class="item">
                    <div class="title">
                        <div class="icon"></div>
                        <h5>Pending Payouts</h5>
                    </div>
                    <div class="text">
                        <span *ngIf="payout_data">{{payout_data.amount}}</span>
                    </div>
                </div>
            </div>                      
        </div>
    </div>
</section> 

<section class="block-explorer-section section bg-bottom">
    <div class="container">              

        <div class="row">
            <div class="col-lg-12">
                <div class="center-heading">
                    <h2 class="section-title">Payout History</h2>
                </div>
            </div>
            <div class="offset-lg-3 col-lg-6">
                <div class="center-text">
                    <p>Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="" id="commissionWrapper">
                    <!-- Nav tabs -->
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active show" data-toggle="tab" href="#tab1">Restaurant Payout History</a>
                        </li>
                        <li class="nav-item" *ngIf="currentUser && currentUser==1">
                            <a class="nav-link" data-toggle="tab" href="#tab2">Driver Payout History</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu2">Menu 2</a>
                        </li> -->
                    </ul>

                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div id="tab1" class="container tab-pane active" ><br>
                            <div class="table-responsive">
                                <table class="table table-striped table-latests" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Restaurant Name</th>
                                            <th class="text-center">State Address</th>
                                            <th class="text-center">Transaction ID</th>
                                            <th class="text-center">Payout Amount</th>
                                            <th class="text-center">Description</th> 
                                            <th class="text-center">Status</th> 
                                            <th class="text-center">Date</th>                                                                   
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="payout_details.status==true">
                                        <tr *ngFor="let list of collection">
                                            <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.restaurant_name}}</a></td>
                                            <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.blockchain_transaction_id}}</a></td>
                                            <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.transaction_id}}</a></td>
                                            <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.payout_amount}}</a></td>
                                            <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.description}}</a></td>
                                            <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.status}}</a></td>
                                            <td><a routerLink="/transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.created_at}}</a></td>
                                        </tr>                            
                                    </tbody>
                                    <tbody *ngIf="payout_details.status==false">
                                        <tr>
                                            <td colspan="3">No Record Found</td>                                                         
                                        </tr>  
                                    </tbody>                                                                          
                                </table>
                            </div> 
                        </div>
                        <div id="tab2" class="container tab-pane fade" *ngIf="currentUser && currentUser==1"><br>
                            <div class="table-responsive">
                                <table class="table table-striped table-latests" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>Driver Name</th>
                                            <th>Block Address</th>
                                            <th>Transaction ID</th>
                                            <th>Payout Amount</th>
                                            <th>Description</th> 
                                            <th>Status</th> 
                                            <th>Date</th>  
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="payout_details.status">
                                        <tr *ngFor="let list of driver_collection">
                                            <td><a href="transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.name}}</a></td>
                                            <td><a href="transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.blockchain_transaction_id}}</a></td>
                                            <td><a href="transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.transaction_id}}</a></td>
                                            <td><a href="transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.payout_amount}}</a></td>
                                            <td><a href="transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.description}}</a></td>
                                            <td><a href="transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.status}}</a></td>
                                            <td><a href="transaction-details/{{list.blockchain_transaction_id}}" style="cursor:pointer">{{list.created_at}}</a></td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="payout_details.status==false">
                                        <tr>
                                            <td colspan="5">No Record Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>                        
                    </div>
                </div>               
            </div>
        </div>

    </div>
</section>


<app-footer></app-footer>