import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

declare var $:any;

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.css']
})
export class RestaurantComponent implements OnInit {

  baseUrl :any = environment.baseUrl;
  imageBase : any = environment.imageBase;
  restaurant_list : any = [];
  restaurant_kyc: any = [];
  config: any;
  collection = { count: 0, data: [] };
  res_address;

  slideConfig = {  
    "slidesToShow": 4,  
    "slidesToScroll": 4,  
    "dots": true,  
    "infinite": true,
    "autoplay": true, 
    "autoplaySpeed": 3000, 
  }; 

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }


  ngOnInit(): void {

    this.get_restaurants(); 

  }

  pageChanged(event){
    this.config.currentPage = event;
  }

  get_restaurants = () => {
      this.http.get(this.baseUrl+'/api/restaurant-list').subscribe( (data:any) => {
        this.restaurant_list = data;
        this.collection.count = this.restaurant_list.data.length;
        this.collection.data = this.restaurant_list.data;
        this.config = {
          itemsPerPage: 10,
          currentPage: 1,
          totalItems: this.collection.count
        };
        return this.collection;
      });
  }

}
