import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

import { AuthCheckService } from "./../service/auth-check.service";

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.css']
})
export class CommissionComponent implements OnInit {

  baseUrl :any = environment.baseUrl;
  commission_data;
  role;
  _id;
  currentUser;
  config: any;
  cityconfig: any;
  collection = { count: 0, data: [] };
  city_collection = { count: 0, data: [] };

  constructor(private authservice: AuthCheckService, private http: HttpClient, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    if(this.authservice.checkAuth())
    {
      this.get_commission_details();
    }      

  }

  pageChanged(event){
    console.log("event1",event)
    this.config.currentPage = event;
  }

  citypageChanged(event){
    console.log("event2",event)
    this.cityconfig.currentPage = event;
  }

  get_commission_details = () => {    
    this.http.get(this.baseUrl+'/api/commission-data').subscribe( (data:any) => {
      console.log(data) 
      this.commission_data = data;
      this.collection.count = this.commission_data.data.restaurant_commission.length;
      this.collection.data = this.commission_data.data.restaurant_commission;
      this.config = {
        id: "restaurant_pagination",
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: this.collection.count
      };
      this.city_collection.count = this.commission_data.data.city_commission.length;
      this.city_collection.data = this.commission_data.data.city_commission;
      this.cityconfig = {
        id: "city_pagination",
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: this.city_collection.count
      };
      console.log(this.collection) 
      return this.collection;
    });
  }

}
